import React from 'react';
import { Container, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Image from 'components/common/Image';

interface IProps {
  description: string;
  title: React.ReactNode;
  cover: string;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: '#F5F6FA',
  },
  image: {
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('xs')]: {
      maxWidth: 'calc(100% - 8px)',
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  descriptionText: {
    fontWeight: 600,
    textTransform: 'uppercase',
    textAlign: 'left',
    fontSize: 15,
    lineHeight: '120%',
    letterSpacing: '0.03em',
    [theme.breakpoints.up('xs')]: {
      paddingRight: theme.spacing(2),
    },
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 700,
      fontSize: 11,
      letterSpacing: '0em',
      marginTop: theme.spacing(7),
      marginBottom: '4px',
    },
  },
}));

const HomepageBanner: React.FC<React.PropsWithChildren<IProps>> = ({ cover, description, title }) => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <div className={classes.root}>
      <Container style={{ maxWidth: 1168 }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item md={6} xs={isMobile ? 10 : 2}>
            <Grid container spacing={isMobile ? 1 : 2} justifyContent="center" alignItems="center">
              <Grid item xs={12} style={{ paddingLeft: isSm ? 0 : 8 }}>
                <Typography className={classes.descriptionText} color="secondary">
                  {description}
                </Typography>
                {title}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={10}>
            <div className={classes.image}>
              <Image src={cover} variant="aspectRatio" alt="banner" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HomepageBanner;
