import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';

import { useHorizontalScroll } from 'hooks';

import CustomChip from 'components/common/CustomChip/CustomChip';
import { getPath } from './dataByMarkets';

interface EventsTagsRowProps {
  tagsRow: any[];
  market: string;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    marginTop: 0,
    marginLeft: 0,
  },
  scrollContainerWrapper: {
    overflow: 'hidden',
    padding: '8px 8px !important',
  },
  scrollContainer: {
    overflowX: 'scroll',
    flexWrap: 'nowrap',
    position: 'relative',
    ...theme.mixins.scrollBarOverflowYAuto,
  },
  browseAllChipContainer: {
    padding: '0px 0px !important',
    margin: '8px 0 8px 0px ',
    borderRight: '1px solid #959595',
  },
  browseAllChip: {
    marginRight: 16,
  },
  chipContainer: {
    '&:first-child': {
      marginLeft: theme.spacing(1),
    },
  },
  chip: {
    height: 40,
    borderRadius: 20,
  },
  chipColor: {
    backgroundColor: theme.palette.custom.gray.light,
    color: theme.palette.text.primary,
  },
  shadowElement: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    boxShadow: 'inset -70px 0px 30px -10px white',
    opacity: 0.8,
    width: 100,
    pointerEvents: 'none',
    zIndex: 2,
  },
}));

const EventsTagsRow: React.FC<React.PropsWithChildren<EventsTagsRowProps>> = (props) => {
  const { market, tagsRow } = props;
  const { classes, cx } = useStyles();
  const [t] = useTranslation('public');
  const router = useRouter();
  const [hasScroll, setHasScroll] = useState(false);

  const scrollRef = useHorizontalScroll();

  const handleClickBrowseAll = () => {
    const route = getPath(market);
    router.push(route.href);
  };

  useEffect(() => {
    const onResize = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setHasScroll(scrollRef?.current?.scrollWidth > scrollRef?.current?.clientWidth);
    };

    onResize();
    const prevWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      if (window.innerWidth !== prevWidth) {
        onResize();
      }
    });
    return () => {
      window.removeEventListener('resize', () => {
        if (window.innerWidth !== prevWidth) {
          onResize();
        }
      });
    };
  }, [scrollRef]);

  return (
    <Grid container direction="row" spacing={2} className={classes.root}>
      {hasScroll && <div className={classes.shadowElement} />}
      <Grid item className={classes.browseAllChipContainer}>
        <CustomChip
          color="primary"
          label={t<string>('screen.homepage.eventTags.browseAllEvents')}
          className={cx(classes.chip, classes.browseAllChip)}
          onClick={handleClickBrowseAll}
        />
      </Grid>
      <Grid item xs className={classes.scrollContainerWrapper}>
        <Grid container spacing={2} className={classes.scrollContainer} ref={scrollRef}>
          {tagsRow.map((tag) => {
            const handleClick = () => {
              router.push(tag.path.href);
            };
            return (
              <Grid item key={tag.title} className={classes.chipContainer}>
                <CustomChip
                  textTransform="capitalize"
                  label={t(`screen.homepage.eventTags.${tag.title}`)}
                  className={cx(classes.chip, classes.chipColor)}
                  onClick={handleClick}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EventsTagsRow;
