import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import Image from 'next/image';

const useStyles = makeStyles()((theme) => ({
  emptyImage: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  },
  text: {
    color: theme.palette.text.disabled,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.2,
    textAlign: 'center',
    letterSpacing: '-0.02em',
  },
}));

const HomepageEmptyState: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  const [t] = useTranslation('public');

  return (
    <Grid container>
      <Grid item xs={12} className={classes.emptyImage}>
        <Image src="/static/img/NoContentHomepage.png" width={621} height={288} alt="NoContentHomepage" />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} className={classes.textContainer}>
            <Typography className={classes.text} align="center">
              {t<string>('screen.homepage.emptyView')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomepageEmptyState;
